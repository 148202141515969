import React, { Suspense } from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import Helmet from 'react-helmet';
import { isParamPresentInURL } from "../utils/universalHelperFunctions";
import ErrorBoundary from "../components/ErrorBoundary";
import { Header, SliceZone, Footer } from "components";
import "bootstrap/dist/css/bootstrap.min.css";
import "../styles/index.css";

// The typenames come from the slice names
// If this doesn't work for you query for __typename in body {} and GraphiQL will show them to you
class Page extends React.Component {
  constructor(props) {
    super(props);
  }
  componentDidMount() {
    var url = window.location.href || '';
    if(isParamPresentInURL('id', url)) {
      let urlParams = new URLSearchParams(window.location.search);
      let value = urlParams.get('id');
      sessionStorage.setItem('email', value);
    }
    if(sessionStorage.getItem('email')) {
      let emailID = sessionStorage.getItem('email');

      let requestBody = `actid=${process.env.ACTIVE_CAMPAIGN_ID}&key=${process.env.ACTIVE_CAMPAIGN_KEY}&event=site_visited&eventdata=${window.location.pathname}&visit=%7B%22email%22%3A%22${emailID}%22%7D`;
      fetch(process.env.ACTIVE_CAMPAIGN_EVENT_REG_URL, {
          method: 'POST',
          mode: 'no-cors',
          cache: 'no-cache',
          headers: {
              'content-type': 'application/x-www-form-urlencoded'
          },
          body: requestBody
        })
      .then(resp => {
        console.log(resp);
      })
      .catch(err => {
        console.log(err);
      });
    }
  }
  render() {
    var isWindow = typeof window !== 'undefined';
    let metaSection = null;
    let markupMetaData = null;
    let propsGraphQl = this.props.data;
    var slicesData = null;
    var pageUid = null;
    var pageData = null;
    var jobId = null;
    var pageDisplayName = null;
    var jobList = propsGraphQl && propsGraphQl.allPrismicJobDescriptionPage ? propsGraphQl.allPrismicJobDescriptionPage.edges : null;

    if(propsGraphQl.prismicResource) {
      slicesData = propsGraphQl.prismicResource.data.body;
      pageUid = propsGraphQl.prismicResource.uid;
      //find the search content though hero desciption/site_search_360_content field / job position
      pageData = propsGraphQl.prismicResource;
      pageDisplayName = propsGraphQl.prismicResource.data.resource_display_name.text;
    } else if(propsGraphQl.prismicJobDescriptionPage) {
      slicesData = propsGraphQl.prismicJobDescriptionPage.data.body;
      pageUid = propsGraphQl.prismicJobDescriptionPage.uid;
      jobId = propsGraphQl.prismicJobDescriptionPage.data.job_id.text;
      //find the search content though hero desciption/site_search_360_content field / job position
      pageData = propsGraphQl.prismicJobDescriptionPage;
      pageDisplayName = propsGraphQl.prismicJobDescriptionPage.data.page_display_name.text;
    } else {
      slicesData = propsGraphQl.prismicPage.data.page_content;
      pageUid = propsGraphQl.prismicPage.uid;
      //find the search content though hero desciption/site_search_360_content field / job position
      pageData = propsGraphQl.prismicPage;
      pageDisplayName = propsGraphQl.prismicPage.data.page_display_name.text;
    }
    let pageURL = this.props.location.href;
    
    let searchConent = null;
    let pageHeaderSlice = -1;
    let resourceOverviewSlice = -1;
    pageHeaderSlice = slicesData && slicesData.findIndex(function (sl) {
      return sl.slice_type === "page_header___body";
    });
    resourceOverviewSlice = slicesData && slicesData.findIndex(function (sl) {
      return sl.slice_type === "resource_overview";
    });
    if(pageHeaderSlice !== -1 && slicesData && slicesData[pageHeaderSlice] && slicesData[pageHeaderSlice].primary) {
      searchConent = slicesData[pageHeaderSlice].primary.body.text;
    } else if(resourceOverviewSlice !== -1 && slicesData && slicesData[resourceOverviewSlice] && slicesData[resourceOverviewSlice].primary) {
      searchConent = slicesData[resourceOverviewSlice].primary.overview.text;

    }else if(pageData && pageData.data && pageData.data.hasOwnProperty('site_search_360_content') && pageData.data.site_search_360_content.html) {
      searchConent = pageData.data.site_search_360_content.raw.text;
    }

    //this flag is marked as a false if we are on an other page than resource landing page
    //We are maintaining the filter only for resource pages and internal navigations and not throughout the website
    if (typeof localStorage !== 'undefined') {
      let resourceFlag = localStorage.getItem('resourceFlag');
      let breadCrumbFlag = localStorage.getItem('breadCrumbFlag');
      let searchWordFlag = localStorage.getItem('searchWordFlag');
      let resourceTagFlag = localStorage.getItem('resourceTagFlag');
      let jobFilterFlag = localStorage.getItem('jobFilterFlag');
      if (resourceFlag && resourceFlag === 'true' && pageUid !== 'all-resources') {
        localStorage.setItem('resourceFlag', 'false');
      }else if(resourceFlag && resourceFlag === 'true' && pageUid === 'all-resources') {
        if(window.performance.navigation.type === 1) {
          localStorage.setItem('resourceFlag', 'false');
        }
      }
      if (searchWordFlag && searchWordFlag === 'true' && pageUid !== 'all-resources') {
        localStorage.setItem('searchWordFlag', 'false');
      }
      if (breadCrumbFlag && breadCrumbFlag === 'true' && pageUid !== 'all-resources') {
        localStorage.setItem('breadCrumbFlag', 'false');
      }
      if (resourceTagFlag && resourceTagFlag === 'true' && pageUid !== 'all-resources') {
        localStorage.setItem('resourceTagFlag', 'false');
      }else if(resourceTagFlag && resourceTagFlag === 'true' && pageUid === 'all-resources') {
        if(window.performance.navigation.type === 1) {
          localStorage.setItem('resourceTagFlag', 'false');
        }
      }
      if (jobFilterFlag && jobFilterFlag === 'true' && pageUid !== 'find-a-position') {
        localStorage.setItem('jobFilterFlag', 'false');
      }else if(jobFilterFlag && jobFilterFlag === 'true' && pageUid === 'find-a-position') {
        if(window.performance.navigation.type === 1) {
          localStorage.setItem('jobFilterFlag', 'false');
        }
      }
    }

    if (slicesData && slicesData.length > 0) {
      let metaDataSlice = slicesData.findIndex(function (sl) {
        return sl.slice_type === "metadata_section";
      });
      let overviewSlice = slicesData.findIndex(function (sl) {
        return sl.slice_type === "page_overview";
      });
      let herosSlice = slicesData.findIndex(sl => sl.slice_type === "heroes");
      if (metaDataSlice !== -1 && overviewSlice !== -1) {
        let metaDataContent = slicesData[metaDataSlice].primary;
        let pageTitle = slicesData[overviewSlice].primary.page_title.text;

        if(!searchConent) {
          searchConent = metaDataContent.metadata_description.text;
        }
        
        metaSection = (
          <Helmet>
            {/* General tags */}
            <title>{pageTitle}</title>
            <meta name="description" content={metaDataContent.metadata_description.text} />
            <meta name="keywords" content={metaDataContent.metadata_keywords.text} />
            <meta name="robots" content={metaDataContent.metadata_robots} />
            <meta http-equiv="Cache-Control" content="no-cache, no-store, must-revalidate" />
            <meta http-equiv="Pragma" content="no-cache" />
            <meta http-equiv="Expires" content="0" />
            <meta name="google-site-verification" content="DfDqsqVWWvRxc2RogNvh-o-MRfddK_RHwYlqBmgVQ7w" />
            <meta name="viewport" content="width=device-width, initial-scale=1" />
            <link rel="canonical" href={pageURL} hrefLang="en"/>
            <link rel="alternate" href={pageURL} hrefLang="en" key="en" />
          </Helmet>
        )
      }
      if(herosSlice !== -1) {
        let heroContent = slicesData && slicesData[herosSlice].items[0];
        if(heroContent) {
          markupMetaData = (
            <Helmet>
              <meta property="og:title" content={heroContent.hero_description.text || pageDisplayName} />
              <meta property="og:url" content={pageURL} />
              <meta property="og:type" content="article" />
              <meta property="og:locale" content="en_US" />
              <meta property="og:description" content={heroContent.hero_multiline_description.text}/>
              <meta property="og:image" content={heroContent.Desktop && heroContent.Desktop.localFile.childImageSharp.fluid.originalImg}/>
              <meta name="twitter:card" content="summary_large_image"/>
              <meta name="twitter:image" content={heroContent.Desktop && heroContent.Desktop.localFile.childImageSharp.fluid.originalImg}/>
            </Helmet>
          )
        }
      }
    }
    console.log('propsGraphQl.prismicCareerMenu: ', propsGraphQl);

    return (
      <ErrorBoundary>
        {metaSection}
        {markupMetaData}
        <div className="pageSearchContent" style={{display: 'none'}}>{searchConent}</div>
        <Header logoUrl={propsGraphQl.prismicCareerMenu.data.logo_url} menuData={propsGraphQl.prismicCareerMenu.data.menu_links} pagesLinksData={propsGraphQl.allPrismicPage.edges.concat(propsGraphQl.allPrismicResource.edges)} />
        {
          isWindow && (
            <Suspense fallback={<div style={{height: window.innerHeight}}></div>}>
              <SliceZone allSlices={slicesData} pageId={pageUid} pageDisplayName={pageDisplayName}
                resourceTilesData={propsGraphQl.allPrismicResource.edges} jobListData={jobList}
                pagesLinksData={propsGraphQl.allPrismicPage.edges} jobID={jobId}
                pageDynamicData={propsGraphQl.allPrismicPagePageContentPageWithCarousel?.nodes}/>
                <Footer footerData={propsGraphQl.prismicCareerFooter.data} allSlices={slicesData} pageId={pageUid} pagesLinksData={propsGraphQl.allPrismicPage.edges.concat(propsGraphQl.allPrismicResource.edges)} />
            </Suspense>
          )
        }
      </ErrorBoundary>
    )
  }
}
export default Page;

Page.propTypes = {
  data: PropTypes.object
};
export const pageQuery = graphql`
query UserQuery($uid: String!) {
  prismicPage(uid: { eq: $uid }) {
    id
    uid
    type
    data {
      page_path {
        text
      }
      page_display_name {
        text
      }
      site_search_360_content {
        text
        html
        raw {
          text
        }
      }
      page_content {
        __typename
        ... on PrismicPagePageContentMetadataSection{
          slice_type
          primary{
            metadata_description{
              text
            }
            metadata_keywords{
              text
            }
            metadata_robots
          }
        }
        ... on PrismicPagePageContentHeroes {
          id
          slice_type
          items {
            image_change
            hero_description {
              text
            }
            hero_multiline_description {
              text
              html
              raw {
                type
                text
              }
            }
            hero_multiline_description_mobile{
              text
              html
              raw {
                type
                text
              }
            }
            hero_background_image {
              Tablet {
                localFile {
                  childImageSharp {
                    fluid (quality: 90) {
                      aspectRatio
                      src
                      srcSet
                      srcWebp
                      srcSetWebp
                      sizes
                      originalImg
                      originalName
                    }
                  }
                }
              }
              MobileHDPI {
                localFile {
                  childImageSharp {
                    fluid (quality: 90) {
                      aspectRatio
                      src
                      srcSet
                      srcWebp
                      srcSetWebp
                      sizes
                      originalImg
                      originalName
                    }
                  }
                }
              }
              Desktop {
                localFile {
                  childImageSharp {
                    fluid (quality: 90) {
                      aspectRatio
                      src
                      srcSet
                      srcWebp
                      srcSetWebp
                      sizes
                      originalImg
                      originalName
                    }
                  }
                }
              }
            }
            video_thumbnail {
              #alt
              url
            }
            hero_button_color
            hero_image_overlay
            hero_headline {
              text
            }
            hero_font_color
            hero_button_link {
              url
              id
              document {
                ... on PrismicJobDescriptionPage {
                  uid
                  data {
                    page_path {
                      text
                    }
                    body {
                      __typename
                      ... on PrismicJobDescriptionPageBodyJobDescription {
                        id
                        primary {
                          description {
                            text
                            html
                            raw {
                              text
                            }
                          }
                          job_title {
                            text
                            html
                            raw {
                              text
                            }
                          }
                        }
                      }
                      ... on PrismicJobDescriptionPageBodyPrimarySkills {
                        id
                        slice_type
                        prismicId
                        items {
                          technology
                        }
                      }
                    }
                  }
                }
              }
            }
            hero_button_text {
              text
            }
            hero_video {
              embed_url
              thumbnail_url
              thumbnail_url_with_play_button
              height
              width
              thumbnail_width
              thumbnail_height
            }
            hero_video_link {
              url
            }
            #hero_background_color
            hero_video_icon_class {
              text
            }
            videoflag
            hero_video_icon_color
            image_visibility_for_mobile
          }
        }
        ... on PrismicPagePageContentPageOverview{
          slice_type
          primary{
            page_title{
              text
            }
          }
        }
        ... on PrismicPagePageContentBreadcrumb {
          id
          slice_type
          items {
            breadcrumb_title {
              text
            }
            breadcrumb_url {
              url
            }
            position
            breadcrumb_label {
              text
            }
          }
        }

        ... on  PrismicPagePageContentCardsWithCertifications {
          prismicId
          slice_type
          items {
            card_image {
              url
            }
            card_link {
              url
            }
            card_title {
              text
            }
          }
        }
      

        ... on PrismicPagePageContentWalkinDetail {
          id
          slice_type
          primary {
            walkin_headline {
              text
            }
            date_and_time {
              text
            }
            interview_venue {
              text
              html
              raw {
                text
              }
            }
            contact_title {
              text
            }
            contact_person {
              text
              html
              raw {
                text
              }
            }
            location_map_url {
              url
            }
            calendar_button_title {
              text
            }
            breadcrumb_id {
              text
            }
          }
        }
        ... on PrismicPagePageContentJobsListForWalkin {
          slice_type
          primary {
            walkin_job_list_header {
              text
            }
          }
          items {
            walkin_job_id {
              text
            }
            job_button_text {
              text
            }
            job_link{
              url
              document {
                ... on PrismicJobDescriptionPage {
                  uid
                  data {
                    body {
                      __typename
                      ... on PrismicJobDescriptionPageBodyJobDescription {
                        id
                        primary {
                          job_title {
                            text
                            html
                            raw {
                              text
                            }
                          }
                        }
                      }
                      ... on PrismicJobDescriptionPageBodyPrimarySkills {
                        id
                        slice_type
                        prismicId
                        items {
                          technology
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }

        ... on  PrismicPagePageContentLatestJobOpenings {
          prismicId
          slice_type
          primary {
            description {
              text
              html
            }
            title {
              html
              text
            }
          }
        }


        ... on PrismicPagePageContentJobsListForWalkin {
          slice_type
          primary {
            walkin_job_list_header {
              text
            }
          }
          items {
            walkin_job_id {
              text
            }
            job_button_text {
              text
            }
          }
        }

        ... on PrismicPagePageContentAwardAndPartnerships {
          slice_type
          primary {
            award_section_heading {
              text
            }
            award_section_subheading {
              text
            }
            breadcrumb_id {
              text
            }
            partnership_section_background
            partner_logo_border_color
            partnership_heading_font_color
            partnership_section_font_color
          }
          items {
            award_image {
              url
              alt
            }
            award_description {
              text
            }
          }
        }

        ... on PrismicPagePageContentFormDisplaySection {
          id
          slice_type
          primary {
            download_form_heading {
              text
            }
            submit_button_text {
              text
            }
            walkin_role_header {
              text
            }
            walkin_role_sub_header {
            text
            }
            form_call_page
            api_url {
              text
            }
            node_api_url {
              text
            }
            breadcrumb_id {
              text
            }
            success_message {
              text
            }
            failure_message {
              text
            }
            event {
              text
            }
            event_data {
              text
            }
            form_section_background_color
            form_section_heading_font_color
            form_section_subheading_font_color
            form_section_font_color
            form_section_button_background
            form_section_button_font_color
            form_identifier
          }

          items {
            field_label {
              text
            }
            field_type {
              text
            }
            field_required
            field_label_2 {
              text
            }
            field_type_2 {
              text
            }
            field_required_2
            field_label_3 {
              text
            }
            field_type_3 {
              text
            }
            field_required_3
          }
        }

        ... on PrismicPagePageContentBannerTech {
          slice_type
          primary {
            banner_tech_title {
              text
            }
            tech_background_color
            tech_font_color
            breadcrumb_id {
              text
            }
          }
          items {
            partner_logo {
              url
              alt
            }
            partner_name {
              text
            }
          }
        }

        ... on PrismicPagePageContentOffices {
          id
          slice_type
          primary {
            office_section_heading {
              text
            }
            office_section_background_color
            office_section_header_font_color
            card_alignment
            is_headquarter_section
          }
          items {
            office_location_font_color
            office_description_font_color
            display_image {
              url
              alt
            }
            location_heading {
              text
            }
            company_name {
              text
            }
            address {
              text
              html
              raw {
                text
              }
            }
            phone {
              text
            }
            email {
              text
            }
            location_link_text {
              text
            }
            location_url {
              text
            }
            location_link_font_color
            map_embed_place {
              text
            }
          }
        }

        ... on PrismicPagePageContentBannerJob {
          id
          slice_type
          primary {
            cta_title {
              text
            }
            adobe_single
            banner_job_background_color
            banner_job_font_color
            banner_job_body {
              text
            }
            job_button_text {
              text
            }
            #job_button_text_link {
            #  url
            #}
            job_search_button_color
            banner_button_font_color
            breadcrumb_id {
              text
            }
            success_message {
              text
            }
            failure_message {
              text
            }
            node_api_url {
              text
            }
            event {
              text
            }
            event_data {
              text
            }
          }
        }
        ... on PrismicPagePageContentPageHeaderBody {
          id
          slice_type
          primary {
            subheading {
              text
            }
            heading {
              text
            }
            mobile_visibility
            body {
              text
              html
              raw {
                text
              }
            }
            breadcrumb_id {
              text
            }
            #image {
            #  dimensions {
            #    width
            #    height
            #  }
            #  url
            #  alt
            #}
          }
        }

        ... on PrismicPagePageContentCards1 {
          slice_type
          id
          primary {
            cards_per_row
            card_section_title {
              text
            }
            card_section_subtitle {
              text
            }
            card_section_description {
              text
            }
            card_section_multiline_description {
              text
              html
              raw {
                text
              }
            }
            breadcrumb_id {
              text
            }
            card_section_background_color
            card_section_font_color
            card_type
          }
          items {
            #card_logo {
            #  url
            #  alt
            #}
            icon_class {
              text
            }
            card_heading {
              text
            }
            card_subheading {
              text
            }
            card_description {
              text
            }
            card_button_link {
             url
            }
            card_button_us_link {
              url
            }
            card_background_color
            card_button_color
            card_button_text {
              text
            }
            icon_color
            card_image {
              url
            }
            on_hover_background_color
          }
        }

        ... on PrismicPagePageContentResourceOverview {
          slice_type
          prismicId
          primary {
            resource_type
            overview {
              text
              html
            }
            document_download_link {
              link_type
              target
            }
          }
        } 
      
        #... on PrismicPagePageContentCardsWithNoBorder {
          # slice_type
          #id
          # primary {
            # cards_per_row
            #
            #card_section_background_color
            # card_section_font_color
            #card_type
            #}
            #items {
              #
              #card_heading {
                #text
                # }
            
                #card_description {
                  #text
                  #}
                  #card_image {
                    #url
                    #}
                    #card_button_text{
                      #text
                      #}
           
                      #}
                      #}

                      #... on   PrismicPagePageContentBrandsWorkWith {
                        #items {
                          #  brands {
                            #  url
                            # alt
                            # }
                            # }
                            # prismicId
                            #  slice_type
                            #  primary {
                              #   header {
                                #    text
                                #  }
                                #  background_color_for_slice
                                # }
                                # }

       # ... on  PrismicPagePageContentAdobeEcosystem {
        #  primary {
          #   content_image {
            #   url
            #}
            #content_part_one {
              #  text
              #}
              #content_part_three {
                #text
                #}
                #content_part_two {
                  #text
                  #}
                  #header {
                    #text
                    #}
                    #content_image_mobile{
                      #url
                      # }
                      #}
                      #prismicId
                      #slice_type
                      #}
        ... on PrismicPagePageContentImageWithData {
          slice_type
          prismicId
          primary {
            description {
              text
              html
            }
            header {
              text
            }
            line_description {
              text
              html
            }
            relationship_image {
              url
            }
            relationship_sub_text_part_1{
              text
            }
            relationship_sub_text_part_2{
              text
            }

          }
        }
        ... on PrismicPagePageContentPageWithDynamicLinks{
          id
          slice_type
          primary {
            video {
       
              embed_url
              thumbnail_url
              thumbnail_url_with_play_button
              height
                    width
                    thumbnail_width
                    thumbnail_height
            }
            expertise {
              text
            }
            page_description {
              html
            }
            page_header_normal_text {
              text
            }
            page_header_highlight_text {
              text
            }
            video {
              embed_url
            }
          }
        }
        ... on PrismicPagePageContentCardsWithHeader {
          prismicId
          slice_type
          primary {
            back_colr_boolean
            heading_boolean
            card_header {
              text
            }
          }
          items {
            read_more_link {
              url
            }
            card_title {
              text
            }
            card_desc {
              text
            }
            card_img {
              url
            }
            card_link {
              text
            }
            card_link_color
          }
        }

      ... on  PrismicPagePageContentHeaderWithSvg {
          primary {
            header {
              html
              text
            }
            image{
              url
            }
          }
          prismicId
          slice_type
          items {
            item_name {
              text
            }
          }
        }
        ... on  PrismicPagePageContentMultiCardsWithImages {
          items {
            breadcrumb_id {
              text
            }
            multi_card_image_heading {
              text
            }
            multi_card_subheading {
              text
            }
            multi_cards_background_color
            multi_cards_image {
              Desktop {
                url
                dimensions {
                  height
                  width
                }
              }
              url
              dimensions {
                height
                width
              }
            }
          }
          primary {
            multicards_description {
              text
            }
            multicards_title {
              text
            }
            section_breadcrumb_id {
              text
            }
          }
          prismicId
          slice_type
          id
        }
      

             
        ... on PrismicPagePageContentWalkinCards {
          id
          slice_type
          primary {
            card_section_title {
              text
            }
            card_section_past_event_subtitle {
              text
            }
            card_section_upcoming_event_subtitle {
              text
            }
            cards_per_row
            is_walkin_card
            no_upcoming_card_message {
              text
              html
              raw {
                text
                type
              }
            }
            no_past_card_message {
              text
              html
              raw {
                text
                type
              }
            }
            breadcrumb_id {
              text
            }
          }
          items {
            card_heading {
              text
            }
            #card_subheading {
            #  text
            #}
            #card_description {
            #  text
            #  html
            #  raw {
            #    text
            #    type
            #  }
            #}
            card_button_link {
              url
            }
            card_button_text {
              text
            }
            event_end_date_and_time(formatString: "YYYY-MM-DDTHH:mm:ss.sssZ")
            event_location {
              text
            }
            event_start_date_and_time(formatString: "YYYY-MM-DDTHH:mm:ss.sssZ")
          }
        }
        ... on PrismicPagePageContentSPattern {
          id
          slice_type
          primary {
            s_pattern_title {
              text
            }
            section_breadcrumb_id {
              text
            }
          }
          items {
            s_pattern_image {
              url
              alt
              Desktop {
                alt
                url
              }
            }
            s_pattern_background_color
            s_pattern_image_position
            s_pattern_text_position
            s_pattern_heading {
              text
            }
            s_pattern_subheading {
              text
            }
            button_text {
              text
            }
            button_link {
              url
            }
            breadcrumb_id {
              text
            }
            s_pattern_button_color
            s_pattern_button_font_color
            button_type
            require_image_magnifier
            #s_pattern_video_thumbnail {
            #  url
            #}
            #s_pattern_bold_heading {
            #  text
            #}
            #s_pattern_video {
            #  embed_url
            #  thumbnail_url
            #  thumbnail_url_with_play_button
            #  height
            #  width
            #  thumbnail_width
            #  thumbnail_height
            #}
            #s_pattern_video_link {
            #  url
            #}
            #s_pattern_video_icon_class {
            #  text
            #}
            #s_pattern_video_icon_color
          }
        }

        ... on PrismicPagePageContentExperts {
          id
          slice_type
          primary {
            expert_section_heading {
              text
            }
            expert_section_description {
              text
            }
            expert_section_background_color
            breadcrumb_id {
              text
            }
            section_alignment
          }
          items {
            expert_image {
              url
              alt
            }
            expert_name {
              text
            }
            expert_position {
              text
            }
            expert_role {
              text
            }
            expert_social_connection {
              text
            }
            expert_link {
              text
            }
            expert_font_color
          }
        }
        ... on PrismicPagePageContentBannerNumbers {
          id
          slice_type
          primary {
            number_banner_heading {
              text
            }
            number_background_color
            breadcrumb_id {
              text
            }
          }
          items {
            number_key {
              text
            }
            number_value {
              text
            }
          }
        }
        ... on PrismicPagePageContentCareerdescription {
          id
          slice_type
          primary {
            title {
              text
            }
            background_colour
            description_link {
              url
              id
            }
            link_text {
              text
            }
            description {
              text
            }
            image_position 
            image {
              url
            }
            image_columns
            display_image_collage
          }
          items {
            repetable_text {
              text
            }
            columns
              #repeatable_image {
                # url
                # }
              carousel_image {
                  url
              }
            
            }
        }

        ... on PrismicPagePageContentGspannValues {
          id
          slice_type
          primary {
            title {
              text
            }
            background_colour
            description {
              text
            }
          }
          items {
            repeatable_heading {
              text
            }
            repeatable_image {
              url
            }
            repeatable_description {
              text
            }
          }
        }

        ... on PrismicPagePageContentDescriptionWithProfiles {
          id
          slice_type
          primary {
            title {
              text
            }
            background_colour
            description {
              text
            }
          }
          items {
            name {
              text
            }
            profile_image { 
              url
            }
            profile_description_popup_image {
              url
            }
            designation {
              text
            }
          }
        }

        ... on PrismicPagePageContentCareerBanner {
          id
          slice_type
          primary {
            secondary_heading {
              text
            }
            primary_heading {
              text
            }
            mobile_view_heading_1 {
              text
            }
            mobile_view_heading_2 {
              text
            }
            mobile_view_heading_3 {
              text
            }
            banner_image {
              url
              Tablet {
                localFile {
                  childImageSharp {
                    fluid (quality: 90) {
                      aspectRatio
                      src
                      srcSet
                      srcWebp
                      srcSetWebp
                      sizes
                      originalImg
                      originalName
                    }
                  }
                }
              }
              MobileHDPI {
                localFile {
                  childImageSharp {
                    fluid (quality: 90) {
                      aspectRatio
                      src
                      srcSet
                      srcWebp
                      srcSetWebp
                      sizes
                      originalImg
                      originalName
                    }
                  }
                }
              }
              Desktop {
                localFile {
                  childImageSharp {
                    fluid (quality: 90) {
                      aspectRatio
                      src
                      srcSet
                      srcWebp
                      srcSetWebp
                      sizes
                      originalImg
                      originalName
                    }
                  }
                }
              }
            }
            banner_description {
              text
            }
          }
          items {
            animated_text {
              text
            }
          }
        }

        ... on  PrismicPagePageContentCareerTestimonial {
          id
          slice_type
          primary {
            background_colour
            testimonial_heading {
              text
            }
          }
          items {
            employee_designation {
              text
            }
            employee_name {
              text
            }
            testimonial_content {
              text
            }
            testimonial_image {
              alt
              url
            }
          }
        }

        ... on PrismicPagePageContentJobsCards {
          id
          slice_type
          primary {
            title {
              text
            }
            background_colour
          }
          items {
            background_image {
              url
            }
            heading {
              text
            }
            page_url {
              url
              id
            }
          }
        }
        ... on PrismicPagePageContentTestimonials {
          id
          slice_type
          primary {
            breadcrumb_id {
              text
            }
          }
          items {
            author_position {
              text
            }
            testimonials_button_background_color
            testimonials_button_font__color
            testimonials_button_link {
              url
              id
            }
            testimonials_button_text {
              text
            }
            testimonials_content_font_color
            testimonials_heading {
              text
            }
            testimonials_image {
              localFile {
                childImageSharp {
                  fluid (quality: 90) {
                    aspectRatio
                    src
                    srcSet
                    srcWebp
                    srcSetWebp
                    sizes
                    originalImg
                    originalName
                  }
                }
              }
              Tablet {
                localFile {
                  childImageSharp {
                    fluid (quality: 90) {
                      aspectRatio
                      src
                      srcSet
                      srcWebp
                      srcSetWebp
                      sizes
                      originalImg
                      originalName
                    }
                  }
                }
              }
              MobileHDPI {
                localFile {
                  childImageSharp {
                    fluid (quality: 90) {
                      aspectRatio
                      src
                      srcSet
                      srcWebp
                      srcSetWebp
                      sizes
                      originalImg
                      originalName
                    }
                  }
                }
              }
            }
          }
        }
        ... on PrismicPagePageContentPrivacyContent {
          id
          slice_type
          primary {
            content_title {
              text
            }
            content_body {
              html
              raw {
                type
                text
              }
              text
            }
          }
        }
        ... on PrismicPagePageContentOverlayFormDisplaySection {
          id
          slice_type
          items {
            form_field_type {
              text
            }
            form_field_label {
              text
            }
            form_field_required
          }
          primary {
            banner_view
            form_section_partition
            section_title {
              text
            }
            #banner_section_description {
            #  html
            #  text
            #  raw {
            #    text
            #  }
            #}
            banner_section_body {
              text
            }
            banner_background_color
            banner_font_color
            banner_buttton_text {
              text
            }
            banner_button_link {
              url
            }
            is_button_attach_to_form_field
            banner_button_background
            banner_button_font_color
            banner_background_image {
              url
              alt
            }
            failure_message {
              text
            }
            success_message {
              text
            }
            duplicate_user_success_message {
              text
            }
            event {
              text
            }
            event_data {
              text
            }
            form_call_page
            node_api_url {
              text
            }
            breadcrumb_id {
              text
            }
            form_identifier
          }
        }
        ... on PrismicPagePageContentEmailTemplateLinks {
          id
          slice_type
          primary {
            form_identifier_for_teamplate
          }
          items {
            user_type
            job_location
            email_title {
              text
            }
            email_template_link {
              url
              document {
                data {
                  body {
                    __typename
                    ... on PrismicEmailTemplateBodyDefaultKeyValue {
                      id
                      slice_type
                      items {
                        key {
                          text
                        }
                        default_value {
                          text
                        }
                        multiline_default_value {
                          html
                          raw {
                            type
                            text
                          }
                        }
                      }
                    }
                    ... on PrismicEmailTemplateBodyRecipientList {
                      id
                      items {
                        team_name {
                          text
                        }
                        recipients {
                          text
                        }
                      }
                      slice_type
                    }
                  }
                  recipient {
                    text
                  }
                  cc_recipient {
                    text
                  }
                  bcc_recipient {
                    text
                  }
                  from_recipient {
                    text
                  }
                  subject {
                    text
                  }
                  salutation {
                    html
                    raw {
                      type
                      text
                    }
                  }
                  email_content{
                    html
                    raw {
                      type
                      text
                    }
                  }
                  signature {
                    html
                    raw {
                      type
                      text
                    }
                  }
                }
              }
            }
            description {
              html
              raw {
                type
                text
              }
            }
          }
        }
        ... on PrismicPagePageContentMultiCardsCarousel {
          id
          items {
            carousel_card_link {
              url
              uid
              target
              id
              document {
                data {
                  resource_display_name {
                    text
                  }
                  page_path {
                    text
                  }
                  site_search_360_content {
                    text
                    html
                    raw {
                      text
                    }
                  }
                  body {
                    __typename
                    ... on PrismicResourceBodyPageOverview {
                      slice_type
                      prismicId
                      primary {
                        page_thumbnail {
                          url
                          alt
                        }
                        page_title {
                          text
                        }
                      }
                    }
                    ... on PrismicResourceBodyResourceOverview {
                      slice_type
                      prismicId
                      primary {
                        resource_type
                        date(formatString: "YYYY-MM-DDTHH:mm:ss.sssZ")
                        title {
                          text
                        }
                      }
                    }
                    ... on PrismicResourceBodyButtonWithText {
                      prismicId
                      slice_type
                      primary {
                        button_desc {
                          text
                        }
                        button_link {
                          url
                        }
                        button_name {
                          text
                        }
                      }
                    }
                    ... on PrismicResourceBodyResourceTags {
                      id
                      prismicId
                      slice_type
                      primary {
                        tag_background_color
                        tag_font_color
                        tag_container_background_color
                        redirection_link {
                          url
                          uid
                          target
                          id
                        }
                        resource_card_tag_color
                      }
                      items {
                        tag_keywords_list
                      }
                    }
                  }
                }
              }
            }
          }
          slice_type
          prismicId
          primary {
            carousel_heading {
              text
            }
            carousel_heading_font_color
            carousel_background_color
          }
        }
        # ... on PrismicPagePageContentResourceMediaCards {
            #  id
            #  slice_type
            #  prismicId
            #  primary {
              #  resource_card_section_description {
                #  text
                #  html
                #  raw {
                  #  text
                #  }
              #  }
              #  resource_card_section_title {
                #  text
              #  }
              #  card_section_background_color
              #  card_section_description_color
              #  card_section_title_color
              #  breadcrumb_id {
                #  text
              #  }
            #  }
            #  items {
              #  resource_card_background_color
              #  resource_card_button1_font_color
              #  resource_card_button2_font_color
              #  resource_card_font_color
              #  resource_card_button1_text {
                #  text
              #  }
              #  resource_card_button2_text {
                #  text
              #  }
              #  resource_card_description {
                #  text
                #  html
                #  raw {
                  #  text
                #  }
              #  }
              #  resource_card_button1_link {
                #  url
                #  id
              #  }
              #  resource_card_button2_link {
                #  url
                #  id
              #  }
              #  resource_card_video_link {
                #  url
              #  }
              #  resource_card_embed_video {
                #  embed_url
                #  thumbnail_url
                #  thumbnail_url_with_play_button
                #  height
                #  width
                #  thumbnail_width
                #  thumbnail_height
              #  }
            #  }
          #  }
        ... on PrismicPagePageContentResourceTags {
          id
          prismicId
          slice_type
          primary {
            tag_background_color
            tag_font_color
            tag_section_heading {
              text
            }
            redirection_link {
              url
              uid
              target
              id
            }
            tag_container_background_color
          }
        }
        ... on PrismicPagePageContentPageHeaderBody {
          id
          slice_type
          primary {
            subheading {
              text
            }
            heading {
              text
            }
            body {
              text
              html
            }
            page_body_html_content {
              text
              html
            }
            page_header_background_color
            page_header_font_color
          }
        }
        ... on PrismicPagePageContentVirtualWalkinDetails {
          primary {
            job_location {
              text
            }
            virtual_walkin_button_color
            virtual_walkin_button_font_color
            virtual_walkin_section_background
            virtual_walkin_section_font_color
            virtual_walkin_title {
              text
            }
            virtual_walkin_title1 {
              text
            }
            virtual_walkin_title2 {
              text
            }
            virtual_walkin_button_text {
              text
            }
            virtual_walkin_content1 {
              text
              raw {
                text
                type
              }
              html
            }
            virtual_walkin_content2 {
              html
              raw {
                text
                type
              }
              text
            }
            virtual_walkin_right_section_heading {
              text
            }
          }
          prismicId
          slice_type
          id
          items {
            virtual_walkin_right_section_title {
              text
            }
            virtual_walkin_right_section_content {
              text
              html
              raw {
                text
                type
              }
            }
          }
        }
      }
    }
  }
  allPrismicPage{
    edges{
      node{
        id
        uid
        data{
          page_display_name{
            text
          }
          page_path{
            text
          }
          site_search_360_content {
            text
            html
            raw {
              text
            }
          }
        }
        
      }
    }
  }
  allPrismicPagePageContentPageWithCarousel {
    nodes {
      primary {
        breadcrumb_id {
          text
        }
      }
    }
  }
  allPrismicResource {
    edges {
      node {
        id
        uid
        href
        data {
          resource_display_name {
            text
          }
          page_path{
            text
          }
          site_search_360_content {
            text
            html
            raw {
              text
            }
          }
          body {
            __typename
            ... on PrismicResourceBodyHeroes {
              id
              slice_type
              items {
                hero_description {
                  text
                }
              }
            }
            ... on PrismicResourceBodyPageOverview {
              primary {
                page_thumbnail {
                  url
                  alt
                }
                page_title {
                  text
                }
              }
            }
            ... on PrismicResourceBodyResourceOverview {
              primary {
                resource_type
                date(formatString: "YYYY-MM-DDTHH:mm:ss.sssZ")
                title {
                  text
                }
              }
            }
            ... on PrismicResourceBodyResourceTags {
              id
              prismicId
              slice_type
              primary {
                tag_background_color
                tag_font_color
                tag_container_background_color
                redirection_link {
                  url
                  uid
                  target
                  id
                }
                resource_card_tag_color
              }
              items {
                tag_keywords_list
              }
            }
          }
        }
      }
    }
  }
  prismicResource(uid: { eq: $uid }) {
    id
    uid
    type
    data {
      resource_display_name {
        text
      }
      site_search_360_content {
        text
        html
        raw {
          text
        }
      }
      body {
        __typename
        ... on PrismicResourceBodyMetadataSection {
          id
          slice_type
          primary {
            metadata_description {
              text
            }
            metadata_keywords {
              text
            }
            metadata_robots
          }
        }
        ... on PrismicResourceBodyFormDisplaySection {
          id
          slice_type
          primary {
            #zendesk_crm_tag_id {
            #  text
            #}
            #campaign_form_heading {
            #  text
            #}
            download_form_heading {
              text
            }
            #download_form_description {
            #  text
            #}
            submit_button_text {
              text
            }
            #campaign_form_button_text {
            #  text
            #}
            success_message {
              text
            }
            #success_message_for_gspann_user {
            #  text
            #}
            failure_message {
              text
            }
            form_call_page
            #file_name {
            #  text
            #}
            api_url {
              text
            }
            node_api_url {
              text
            }
            #campaignid {
            #  text
            #}
            #file_content {
            #  url
            #}
            event {
              text
            }
            event_data {
              text
            }
            form_section_background_color
            form_section_heading_font_color
            form_section_subheading_font_color
            form_section_font_color
            form_section_button_background
            form_section_button_font_color
            #form_identifier
          }
          items {
            field_label {
              text
            }
            field_type {
              text
            }
            #field_required
            field_label_2 {
              text
            }
            field_type_2 {
              text
            }
            #field_required_2
            #field_label_3 {
            #  text
            #}
            #field_type_3 {
            #  text
            #}
            #field_required_3
          }
        }
        ... on PrismicResourceBodyHeroes {
          id
          slice_type
          items {
            adobe_summit
            hero_headline {
              text
            }
            hero_description {
              text
            }
            hero_multiline_description {
              text
              html
              raw {
                type
                text
              }
            }
            hero_background_image {
              Tablet {
                localFile {
                  childImageSharp {
                    fluid (quality: 90) {
                      aspectRatio
                      src
                      srcSet
                      srcWebp
                      srcSetWebp
                      sizes
                      originalImg
                      originalName
                    }
                  }
                }
              }
              MobileHDPI {
                localFile {
                  childImageSharp {
                    fluid (quality: 90) {
                      aspectRatio
                      src
                      srcSet
                      srcWebp
                      srcSetWebp
                      sizes
                      originalImg
                      originalName
                    }
                  }
                }
              }
              Desktop {
                localFile {
                  childImageSharp {
                    fluid (quality: 90) {
                      aspectRatio
                      src
                      srcSet
                      srcWebp
                      srcSetWebp
                      sizes
                      originalImg
                      originalName
                    }
                  }
                }
              }
            }
            hero_image_overlay
            hero_button_color
            hero_font_color
            image_visibility_for_mobile
          }
        }
        ... on PrismicResourceBodyResourceOverview {
          id
          slice_type
          primary {
            highlighter_position
            highlighter_color
            highlighter_font_color
            highlighter_title {
              text
            }
            highlighter_content {
              text
              html
              raw {
                text
              }
            }
            title {
              text
            }
            overview {
              html
              text
              raw {
                type
                text
              }
            }
           # overview_image{
           #   url
           # }
           # overview_after_image{
           #   html
           #  text
           #   raw {
            #    type
            #    text
            # }
           # }
            date(formatString: "YYYY-MM-DDTHH:mm:ss.sssZ")
            event_booth_number{
              text
            }
            event_start_date(formatString: "YYYY-MM-DDTHH:mm:ss.sssZ")
            event_end_date(formatString: "YYYY-MM-DDTHH:mm:ss.sssZ")
            event_start_timestamp(formatString: "YYYY-MM-DDTHH:mm:ss.sssZ")
            event_end_timestamp(formatString: "YYYY-MM-DDTHH:mm:ss.sssZ")
            resource_type
            #document_download_link {
            #  url
            #}
            downloadable_document_name {
              text
            }
            breadcrumb_id {
              text
            }
          }
        }
        ... on PrismicResourceBodyAuthor {
          primary {
            author_name {
              text
            }
            author_image {
              url
              alt
            }
            designation {
              text
            }
          }
        }
        ... on PrismicResourceBodyPageOverview {
          id
          slice_type
          primary {
            page_heading {
              text
            }
            page_title {
              text
            }
            page_thumbnail {
              url
              alt
            }
          }
        }
        ... on PrismicResourceBodyEmailTemplateLinks {
          id
          slice_type
          primary {
            form_identifier_for_teamplate
          }
          items {
            user_type
            email_title {
              text
            }
            email_template_link {
              url
              document {
                data {
                  body {
                    __typename
                    ... on PrismicEmailTemplateBodyDefaultKeyValue {
                      id
                      slice_type
                      items {
                        key {
                          text
                        }
                        default_value {
                          text
                        }
                        multiline_default_value {
                          html
                          raw {
                            type
                            text
                          }
                        }
                      }
                    }
                    ... on PrismicEmailTemplateBodyRecipientList {
                      id
                      items {
                        team_name {
                          text
                        }
                        recipients {
                          text
                        }
                      }
                      slice_type
                    }
                  }
                  recipient {
                    text
                  }
                  cc_recipient {
                    text
                  }
                  bcc_recipient {
                    text
                  }
                  from_recipient {
                    text
                  }
                  subject {
                    text
                  }
                  salutation {
                    html
                    raw {
                      type
                      text
                    }
                  }
                  email_content{
                    html
                    raw {
                      type
                      text
                    }
                  }
                  signature {
                    html
                    raw {
                      type
                      text
                    }
                  }
                }
              }
            }
            description {
              html
              raw {
                type
                text
              }
            }
          }
        }
        ... on PrismicResourceBodyMultiCardsCarousel {
          id
          items {
            carousel_card_link {
              url
              uid
              target
              id
              document {
                data {
                  resource_display_name {
                    text
                  }
                  page_path {
                    text
                  }
                  site_search_360_content {
                    text
                    html
                    raw {
                      text
                    }
                  }
                  body {
                    __typename
                    ... on PrismicResourceBodyPageOverview {
                      slice_type
                      prismicId
                      primary {
                        page_thumbnail {
                          url
                          alt
                        }
                        page_title {
                          text
                        }
                      }
                    }
                    ... on PrismicResourceBodyResourceOverview {
                      slice_type
                      prismicId
                      primary {
                        resource_type
                        date(formatString: "YYYY-MM-DDTHH:mm:ss.sssZ")
                        title {
                          text
                        }
                      }
                    }
                    ... on PrismicResourceBodyResourceTags {
                      id
                      prismicId
                      slice_type
                      primary {
                        tag_background_color
                        tag_font_color
                        tag_container_background_color
                        redirection_link {
                          url
                          uid
                          target
                          id
                        }
                        resource_card_tag_color
                      }
                      items {
                        tag_keywords_list
                      }
                    }
                  }
                }
              }
            }
          }
          slice_type
          prismicId
          primary {
            carousel_heading {
              text
            }
            carousel_heading_font_color
            carousel_background_color
          }
        }
        ... on PrismicResourceBodyResourceTags {
          id
          prismicId
          slice_type
          primary {
            tag_background_color
            tag_font_color
            redirection_link {
              url
              uid
              target
              id
            }
            resource_card_tag_color
            tag_container_background_color
          }
          items {
            tag_keywords_list
          }
        }

        ... on   PrismicResourceBodyOverlayFormDisplaySection1 {
          primary {
            banner_buttton_text {
              text
            }
            file_content {
              url
            }
            form_call_page
            zendesk_crm_tag_id {
              text
            }
          }
          prismicId
          slice_type
        }
        ... on PrismicResourceBodyOverlayFormDisplaySection {
          id
          slice_type
          items {
            form_field_type {
              text
            }
            form_field_label {
              text
            }
            form_field_required
          }
          primary {
            form_call_page
            form_section_partition
            zendesk_crm_tag_id {
              text
            }
            banner_view
            campaign_form_heading {
              text
            }
            document_form_link {
              url
              target
              uid
              document {
                data {
                  resource_display_name {
                    text
                  }
                  body {
                    __typename
                    ... on PrismicResourceBodyPageOverview {
                      id
                      primary {
                        page_thumbnail {
                          url
                          alt
                        }
                      }
                    }
                    ... on PrismicResourceBodyEmailTemplateLinks {
                      id
                      slice_type
                      primary {
                        form_identifier_for_teamplate
                      }
                      items {
                        user_type
                        email_title {
                          text
                        }
                        email_template_link {
                          url
                          document {
                            data {
                              body {
                                __typename
                                ... on PrismicEmailTemplateBodyDefaultKeyValue {
                                  id
                                  slice_type
                                  items {
                                    key {
                                      text
                                    }
                                    default_value {
                                      text
                                    }
                                    multiline_default_value {
                                      html
                                      raw {
                                        type
                                        text
                                      }
                                    }
                                  }
                                }
                                ... on PrismicEmailTemplateBodyRecipientList {
                                  id
                                  items {
                                    team_name {
                                      text
                                    }
                                    recipients {
                                      text
                                    }
                                  }
                                  slice_type
                                }
                              }
                              recipient {
                                text
                              }
                              cc_recipient {
                                text
                              }
                              bcc_recipient {
                                text
                              }
                              from_recipient {
                                text
                              }
                              subject {
                                text
                              }
                              salutation {
                                html
                                raw {
                                  type
                                  text
                                }
                              }
                              email_content{
                                html
                                raw {
                                  type
                                  text
                                }
                              }
                              signature {
                                html
                                raw {
                                  type
                                  text
                                }
                              }
                            }
                          }
                        }
                        description {
                          html
                          raw {
                            type
                            text
                          }
                        }
                      }
                    }
                    ... on PrismicResourceBodyOverlayFormDisplaySection {
                      id
                      primary {
                        form_identifier
                      }
                    }
                  }
                }
              }
            }
            file_content {
              url
            }
            section_title {
              text
            }
            banner_section_description {
              html
              text
              raw {
                text
              }
            }
            banner_section_body {
              text
            }
            banner_background_color
            banner_font_color
            banner_buttton_text {
              text
            }
            campaign_form_button_text {
              text
            }
            is_button_attach_to_form_field
            #banner_button_link {
            #  url
            #}
            banner_button_background
            banner_button_font_color
            #banner_background_image {
            #  url
            #  alt
            #}
            failure_message {
              text
            }
            success_message {
              text
            }
            success_message_for_gspann_user {
              text
            }
            #duplicate_user_success_message {
            #  text
            #}
            event {
              text
            }
            event_data {
              text
            }
            form_call_page
            node_api_url {
              text
            }
            form_identifier
            #breadcrumb_id {
            #  text
            #}
            #campaignid {
            #  text
            #}
          }
        }
        ... on PrismicResourceBodyBreadcrumb {
          id
          slice_type
          items {
            breadcrumb_title {
              text
            }
            breadcrumb_url {
              url
            }
            position
            breadcrumb_label {
              text
            }
          }
        }
        ... on PrismicResourceBodyResourceDescription {
          primary {
            highlighter_position
            highlighter_color
            highlighter_font_color
            highlighter_title {
              text
            }
            highlighter_content {
              text
              html
              raw {
                text
              }
            }
            position_of_description
            position_of_repeatable_section
            resource_section_background_color
            resource_section_font_color
            section_partition
            repeatable_section_heading {
              text
            }
            resource_section_description {
              text
              html
              raw {
                text
              }
            }
             resource_section_image{
               url
             }
             resource_section_description_after_image{
               text
               html
               raw {
                 text
               }
             }
            resource_section_heading {
              html
              text
              raw {
                text
              }
            }
            header_font_color
            resource_section_description_in_bold {
              text
              html
              raw {
                text
              }
            }
            breadcrumb_id {
              text
            }
          }
          slice_type
          prismicId
          id
          items {
            repeatable_section_description {
              text
              html
              raw {
                text
              }
            }
            repeatable_section_title {
              text
            }
            repeatable_section_link_of_title {
              url
              id
              type
            }
          }
        }
      }
    }
  }
  allPrismicJobDescriptionPage {
    edges {
      node {
        uid
        prismicId
        type
        id
        href
        data {
          body {
            __typename
            ... on PrismicJobDescriptionPageBodyPageOverview {
              id
            }
            ... on PrismicJobDescriptionPageBodyKeyDetails {
              id
              primary {
                role_type_field_name {
                  text
                }
                section_content_font_color
                location_field_name {
                  text
                }
                key_section_heading_font_color
                key_details_section_background
                job_role_type {
                  html
                  raw {
                    text
                  }
                  text
                }
                job_published_date_field_name {
                  text
                }
                job_published_date(formatString: "YYYY-MM-DDTHH:mm:ss.sssZ")
                job_location {
                  text
                  html
                  raw {
                    text
                  }
                }
                job_experience_field_name {
                  text
                }
                experience_required {
                  text
                  html
                  raw {
                    text
                  }
                }
              }
            }
            ... on PrismicJobDescriptionPageBodyJobTags {
              id
              prismicId
              slice_type
              primary {
                tag_background_color
                tag_font_color
                tag_container_background_color
                redirection_link {
                  url
                  uid
                  target
                  id
                }
                resource_card_tag_color
              }
              items {
                tag_priority
                tag_keywords_list
              }
            }
            ... on PrismicJobDescriptionPageBodyJobDescription {
              id
              primary {
                description {
                  text
                  html
                  raw {
                    text
                  }
                }
                job_title {
                  html
                  text
                  raw {
                    text
                  }
                }
                section_content_font_color
                section_heading_font_color
                job_skills_font_color
                description_section_background_color
              }
            }
            ... on PrismicJobDescriptionPageBodyPrimarySkills {
              id
              slice_type
              prismicId
              items {
                technology
              }
            }
          }
          page_display_name {
            text
          }
          page_path {
            text
          }
          include_job_in_list
          for_freshers
          job_visible_on_homepage
          job_id {
            text
          }
        }
      }
    }
  }
  prismicJobDescriptionPage(uid: { eq: $uid }) {
    id
    uid
    type
    prismicId
    href
    data {
      body {
        __typename
        ... on PrismicJobDescriptionPageBodyEmailTemplateLinks {
          id
          slice_type
          primary {
            form_identifier_for_teamplate
          }
          items {
            user_type
            job_location
            email_title {
              text
            }
            email_template_link {
              url
              document {
                data {
                  body {
                    __typename
                    ... on PrismicEmailTemplateBodyDefaultKeyValue {
                      id
                      slice_type
                      items {
                        key {
                          text
                        }
                        default_value {
                          text
                        }
                        multiline_default_value {
                          html
                          raw {
                            type
                            text
                          }
                        }
                      }
                    }
                    ... on PrismicEmailTemplateBodyRecipientList {
                      id
                      items {
                        team_name {
                          text
                        }
                        recipients {
                          text
                        }
                      }
                      slice_type
                    }
                  }
                  recipient {
                    text
                  }
                  cc_recipient {
                    text
                  }
                  bcc_recipient {
                    text
                  }
                  from_recipient {
                    text
                  }
                  subject {
                    text
                  }
                  salutation {
                    html
                    raw {
                      type
                      text
                    }
                  }
                  email_content {
                    html
                    raw {
                      type
                      text
                    }
                  }
                  signature {
                    html
                    raw {
                      type
                      text
                    }
                  }
                }
              }
            }
          }
        }
        ... on PrismicJobDescriptionPageBodyFormDisplaySection {
          id
          slice_type
          primary {
            submit_button_text {
              text
            }
            walkin_role_header {
              text
            }
            form_call_page
            node_api_url {
              text
            }
            breadcrumb_id {
              text
            }
            success_message {
              text
            }
            failure_message {
              text
            }
            event {
              text
            }
            event_data {
              text
            }
            form_section_background_color
            form_section_heading_font_color
            form_section_subheading_font_color
            form_section_font_color
            form_section_button_background
            form_section_button_font_color
            form_identifier
          }
          items {
            field_label {
              text
            }
            field_type {
              text
            }
            field_required
            field_label_2 {
              text
            }
            field_type_2 {
              text
            }
            field_required_2
          }
        }
        ... on PrismicJobDescriptionPageBodyJobDescription {
          id
          slice_type
          prismicId
          primary {
            apply_button_background_color
            apply_button_font_color
            description_section_background_color
            job_skills_font_color
            section_content_font_color
            section_heading_font_color
            apply_button_text {
              text
            }
            description {
              html
              raw {
                text
                type
              }
              text
            }
            description_field_title {
              text
            }
            job_title {
              text
            }
          }
          items {
            job_details {
              text
              html
              raw {
                text
                type
              }
            }
            job_details_heading {
              text
            }
          }
        }
        ... on PrismicJobDescriptionPageBodyJobTags {
          id
          prismicId
          slice_type
          primary {
            tag_background_color
            tag_font_color
            tag_container_background_color
            redirection_link {
              url
              uid
              target
              id
            }
            resource_card_tag_color
          }
          items {
            tag_priority
            tag_keywords_list
          }
        }
        ... on PrismicJobDescriptionPageBodyKeyDetails {
          id
          slice_type
          prismicId
          primary {
            job_published_date(formatString: "YYYY-MM-DDTHH:mm:ss.sssZ")
            key_details_section_background
            key_section_heading_font_color
            section_content_font_color
            experience_required {
              text
              html
              raw {
                text
                type
              }
            }
            job_experience_field_name {
              text
            }
            job_location {
              text
              html
              raw {
                text
                type
              }
            }
            job_published_date_field_name {
              text
              html
              raw {
                text
                type
              }
            }
            job_role_type {
              html
              raw {
                type
                text
              }
              text
            }
            location_field_name {
              text
            }
            role_type_field_name {
              text
            }
            section_heading {
              text
            }
          }
        }
        ... on PrismicJobDescriptionPageBodyMetadataSection {
          id
          slice_type
          prismicId
          primary {
            metadata_description {
              html
              text
              raw {
                text
                type
              }
            }
            metadata_keywords {
              html
              raw {
                text
              }
              text
            }
            metadata_robots
          }
        }
        ... on PrismicJobDescriptionPageBodyPageOverview {
          id
          slice_type
          prismicId
          primary {
            page_title {
              text
            }
          }
        }
        ... on PrismicJobDescriptionPageBodyPrimarySkills {
          id
          slice_type
          prismicId
          items {
            technology
          }
        }
      }
      job_id {
        text
      }
      job_visible_on_homepage
      page_path {
        text
      }
      page_display_name {
        text
      }
      include_job_in_list
      for_freshers
    }
  }
  prismicHeromenu {
    id
    data {
      display_name {
        html
      }
      nav {
        primary {
          label {
            html
            text
          }
          link {
            url
          }
          label_font_color
          label_font_color_on_hover
          nav_item_background_color
          sub_link_separator_line_color
          sub_link_sub_text_font_color
          sub_link_type_font_color
          sub_nav_link_label_font_color
          nav_column_count
          nav_item_position
        }
        items {
          sub_nav_column_position
          sub_nav_link_lable {
            text
          }
          sub_nav_link {
            url
          }
          sub_link_sub_text {
            text
          }
          sub_link_type {
            text
          }
        }
      }
    }
  }
  prismicCareerMenu {
    id
    data {
      logo_url {
        url
      }
      menu_links {
        link_label {
          text
        }
        link {
          document {
            id
            uid
            href
            data {
              page_path {
                text
              }
              page_display_name {
                text
              }
            }
          }
          url
        }
      }
    }
  }

  prismicFooter {
    data {
      footer_background_color
      footer_font_color
      address {
        text
      }
      copyright_text {
        text
      }
      footer_logo {
        url
      }
      body {
        __typename
        ... on PrismicFooterBodySocialConnection {
          id
          slice_type
          primary {
            social_connection_type {
              text
            }
            social_connection_font_color
          }
          items {
            social_icon
            social_icon_font_color
            social_icon_link {
              url
            }
          }
        }
        ... on PrismicFooterBodyGspannPolicy {
          id
          slice_type
          items {
            policy_name {
              text
            }
            policy_link {
              url
            }
            policy_font_color
          }
        }
        ... on PrismicFooterBodyFooterMenu {
          id
          slice_type
          primary {
            footer_menu_type {
              text
            }
            footer_menu_link {
              url
            }
            menu_type_font_color
          }
          items {
            footer_menu_text {
              text
            }
            footer_menu_link {
              url
            }
            footer_menu_font_color
          }
        }
      }
    }
  }
  prismicCareerFooter {
    data {
      footer_background_color
      footer_font_color
      address {
        text
      }
      copyright_text {
        text
      }
      footer_logo {
        url
      }
      footer_description {
        text
      }
      event {
        text
      } 
      event_data {
        text
      }
      success_message {
        text
      }
      failure_message {
        text
      }
      node_api_url {
        text
      }
      form_call_page 
      body {
        __typename
        ... on PrismicCareerFooterBodySocialConnection {
          id
          slice_type
          primary {
            social_connection_type {
              text
            }
            social_connection_font_color
          }
          items {
            social_icon
            social_icon_font_color
            social_icon_link {
              url
            }
          }
        }
        
        ... on PrismicCareerFooterBodyEmailTemplateLinks {
          id
          slice_type
          primary {
            form_identifier_for_teamplate
          }
          items {
            user_type
            email_title {
              text
            }
            email_template_link {
              url
              document {
                data {
                  body {
                    __typename
                    ... on PrismicEmailTemplateBodyDefaultKeyValue {
                      id
                      slice_type
                      items {
                        key {
                          text
                        }
                        default_value {
                          text
                        }
                        multiline_default_value {
                          html
                          raw {
                            type
                            text
                          }
                        }
                      }
                    }
                    ... on PrismicEmailTemplateBodyRecipientList {
                      id
                      items {
                        team_name {
                          text
                        }
                        recipients {
                          text
                        }
                      }
                      slice_type
                    }
                  }
                  recipient {
                    text
                  }
                  cc_recipient {
                    text
                  }
                  bcc_recipient {
                    text
                  }
                  from_recipient {
                    text
                  }
                  subject {
                    text
                  }
                  salutation {
                    html
                    raw {
                      type
                      text
                    }
                  }
                  email_content{
                    html
                    raw {
                      type
                      text
                    }
                  }
                  signature {
                    html
                    raw {
                      type
                      text
                    }
                  }
                }
              }
            }
          }
        }
        ... on PrismicCareerFooterBodyGspannPolicy {
          id
          slice_type
          items {
            policy_name {
              text
            }
            policy_link {
              url
            }
            policy_font_color
          }
        }
        ... on PrismicCareerFooterBodyFooterMenu {
          id
          slice_type
          items {
            footer_menu_text {
              text
            }
            footer_menu_link {
              url
            }
            footer_menu_font_color
          }
        }

        ... on PrismicCareerFooterBodyEmailTemplateLinks {
          id
          slice_type
          primary {
            form_identifier_for_teamplate
          }
          items {
            user_type
            
            email_title {
              text
            }
            email_template_link {
              url
              document {
                data {
                  body {
                    __typename
                    ... on PrismicEmailTemplateBodyDefaultKeyValue {
                      id
                      slice_type
                      items {
                        key {
                          text
                        }
                        default_value {
                          text
                        }
                        multiline_default_value {
                          html
                          raw {
                            type
                            text
                          }
                        }
                      }
                    }
                    ... on PrismicEmailTemplateBodyRecipientList {
                      id
                      items {
                        team_name {
                          text
                        }
                        recipients {
                          text
                        }
                      }
                      slice_type
                    }
                  }
                  recipient {
                    text
                  }
                  cc_recipient {
                    text
                  }
                  bcc_recipient {
                    text
                  }
                  from_recipient {
                    text
                  }
                  subject {
                    text
                  }
                  salutation {
                    html
                    raw {
                      type
                      text
                    }
                  }
                  email_content{
                    html
                    raw {
                      type
                      text
                    }
                  }
                  signature {
                    html
                    raw {
                      type
                      text
                    }
                  }
                }
              }
            }
          }
        }
        
      }
    }
  }
}
`;
